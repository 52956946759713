import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../authentication/Services/authentication.service';
import { User } from 'src/app/authentication/user.model';
import { PagesService } from '../pages.service';
import { TranslationService } from 'src/app/shared/translation.service';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(
    private pagesService: PagesService,
    private authSer: AuthenticationService,
    public translate: TranslationService,
    private route: ActivatedRoute) {
  }

  karavanLength: number;

  userData: User;


  ngOnInit(): void {

    //get user data
    this.userData = this.authSer.getUser();
    console.log(this.userData);
    this.pagesService.filtterSearchKaravanObject.subscribe(
      (response: any) => {
        console.log('search object filtter ===> ', response);
        this.pagesService.getKaravansBySearch(response,1).subscribe(
          (response: any) => {
            console.log('search karavan data', response);
            if(response.data) {
              this.karavanLength = response.data?.resCarvans.length;
            } else {
              this.karavanLength = 0;
            }
            this.pagesService.karavans.next(response.data);
          },
          (error) => {
            console.log(error);
          }
        )
      }
    )
  }


}
