<div class="side-filtter row m-0" [ngClass]="{'directionContent': translate.is_arabic, 'directionLeft': !translate.is_arabic}">
  <div class="col-12 col-md-3">
    <app-search-side-menu></app-search-side-menu>
  </div>
  <div class="col-12 col-md-9">
    <app-data-result [karavanLen]="karavanLength"></app-data-result>
  </div>
  <router-outlet></router-outlet>
</div>

