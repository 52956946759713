<div [ngClass]="{'sideMenu': translate.is_arabic, 'sideMenuEn': !translate.is_arabic}">
  <div class="header sticky-top d-md-block d-none">
    <p>{{translate.data.result.filttring}}</p>
  </div>

  <div class="header sticky-top d-md-none d-block" data-bs-toggle="collapse" href="#collapseExampleStore" role="button" aria-expanded="false" aria-controls="collapseExampleStore">
    <p>{{translate.data.result.filttring}}</p>
  </div>

  <div class="body collapse d-md-inline-block" id="collapseExampleStore">
    <p class="title">{{translate.data.result.caravan}}</p>

    <div class="block">
      <p class="label">{{translate.data.result.model}}</p>
        <div class="form-floating">
          <select class="form-select"
            id="floatingSelect"
            [(ngModel)]="selectedCityCode"
            aria-label="Floating label select example">
            <option *ngFor="let item of cities" [value]="item.code">{{item.name}}</option>
          </select>
        </div>
    </div>

    <div class="block">
      <p class="label">{{translate.data.result.yearOfManufacture}}</p>
      <div class="form-floating">
        <select class="form-select"
          id="floatingSelect"
          (change)="handelChangeYear($event)"
          [(ngModel)]="selectedYearsValue"
          aria-label="Floating label select example">
          <option *ngFor="let year of years" [value]="year.value">{{year.name}}</option>
        </select>
      </div>
    </div>

    <div class="block">
      <p class="label">{{translate.data.result.type}}</p>
      <div class="row flex-wrap justify-content-between">

        <div class="cardAll" value="الكل" (click)="handleAllType()">
          <div class="parentDiv">
            <p class="parentDiv">{{translate.data.result.all}}</p>
          </div>
        </div>

        <div class="cards"
             *ngFor="let type of carvanTypesResponse"
              (click)="handleType(type)"
              [ngClass]="{'active': type.active}">
          <div class="parentDiv"><img class="parentDiv" src={{sharedService.ipRootImg+type?.photo}} alt=""></div>
            <p class="parentDiv">{{type?.nameAr}}</p>
        </div>

        <div>
        <p class="checktitle">{{translate.data.result.caravanLength}}</p>
        <p-slider [(ngModel)]="rangeValues" [range]="true" [min]="0" [max]="1000" (onChange)="sliderlength()"></p-slider>
        <div class="d-flex flex-wrap justify-content-between">
          <div class="ele">
            <label for="">{{translate.data.result.lessLength}}</label>
            <input type="text" [(ngModel)]='length1' (change)="handleLength()">
          </div>
          <div class="ele">
            <label for="">{{translate.data.result.maximumLength}}</label>
            <input type="text" [(ngModel)]='length2'(change)="handleLength()" >
          </div>
        </div>
        </div>

        <div>
          <p class="checktitle">{{translate.data.result.PricePerNight}}</p>
          <p-slider [(ngModel)]="rangeValues2" [range]="true" [min]="0" [max]="1000" (onChange)="sliderPrice()"></p-slider>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="ele">
              <label for="">{{translate.data.result.lessPrice}}</label>
              <input type="text"  [(ngModel)]='price1' (change)="handlePrice()" >
            </div>
            <div class="ele">
              <label for="">{{translate.data.result.maximumPrice}}</label>
              <input type="text" [(ngModel)]='price2' (change)="handlePrice()" >
            </div>
          </div>

        </div>

        <p class="checktitle">{{translate.data.result.features}}</p>
        <div>

        <div class="mb-2 form-group" *ngFor = "let feature of features">
          <p class="sub-title">{{feature?.nameAr}}</p>
          <div class="form-check" *ngFor="let item of feature.featureItems">
            <input class="form-check-input" type="checkbox" [(ngModel)]="item.isChecked" (change)="onChangeFeature(item)">
            <label class="form-check-label" for="flexCheckChecked">
              {{item ? item[translate.data.requestdData.genName] : 'N/A'}}
            </label>
          </div>
        </div>

        <div *ngFor="let rule of rules">
          <p class="EnglishFont">{{rule ? rule[translate.data.requestdData.genName] : 'N/A'}}</p>
          <div class="form-check" *ngFor="let item of rule.roleItems" >
            <input class="form-check-input" type="checkbox" [(ngModel)]="item.isChecked" (change)="handelChangeRule(item)">
            <label class="form-check-label">
              {{item ? item[translate.data.requestdData.genName] : 'N/A'}}
            </label>
          </div>
        </div>

       <div>
          <p class="checktitle">{{translate.data.result.cancelReserve}}</p>
          <div class="form-check"  *ngFor="let c of cancel">
            <input class="form-check-input" type="checkbox" [(ngModel)]="c.isChecked" (change)="handelCancelChange(c)">
            <label class="form-check-label" for="flexCheckChecked">
             {{c ? c[translate.data.requestdData.genName] : 'N/A'}}
            </label>
          </div>
        </div>


      <div>
          <p class="checktitle">{{translate.data.result.rating}}</p>
          <div class="form-check" style="margin-right: 15px;margin-left: -8px;">
            <input class="form-check-input"
             type="checkbox"
             id="flexCheckChecked"
             [(ngModel)]="isCheckedRate5"
             (change)="handelChangeRate(isCheckedRate5, 'six')"
             style="margin: 7px -17px;">
           <!--  <p-rating [(ngModel)]="val4" [cancel] = "false"></p-rating> -->
           <star-rating [value]="val5"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="24px"
                      [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check" style="margin-right: 15px;margin-left: -8px;">
            <input class="form-check-input"
             type="checkbox"
            [(ngModel)]="isCheckedRate4"
            (change)="handelChangeRate(isCheckedRate4, 'five')"
            id="flexCheckChecked"
            style="margin: 7px -17px;">
           <!--  <p-rating [(ngModel)]="val4" [cancel] = "false"></p-rating> -->
           <star-rating [value]="val4"
                      [totalstars]="5"
                      checkedcolor="#ed6c37"
                      uncheckedcolor="rgb(224, 224, 224)"
                      size="24px"
                      [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check" style="margin-right: 15px;margin-left: -8px;">
            <input class="form-check-input"
             type="checkbox"
             id="flexCheckChecked"
             style="margin: 7px -17px;"
             (change)="handelChangeRate(isCheckedRate3, 'fourth')"
            [(ngModel)]="isCheckedRate3">
            <!-- <p-rating [(ngModel)]="val3" [cancel] = "false"></p-rating> -->
            <star-rating [value]="val3"
                        [totalstars]="5"
                        checkedcolor="#ed6c37"
                        uncheckedcolor="rgb(224, 224, 224)"
                        size="24px"
                        [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check" style="margin-right: 15px;margin-left: -8px;">
            <input class="form-check-input"
                   type="checkbox"
                   id="flexCheckChecked"
                   [(ngModel)]="isCheckedRate2"
                   (change)="handelChangeRate(isCheckedRate2, 'third')"
                    style="margin: 7px -17px;">
            <!-- <p-rating [(ngModel)]="val2" [cancel] = "false"></p-rating> -->
            <star-rating [value]="val2"
                          [totalstars]="5"
                          checkedcolor="#ed6c37"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
            </star-rating>
          </div>

          <div class="form-check" style="margin-right: 15px;margin-left: -8px;">
            <input class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="isCheckedRate1"
                  (change)="handelChangeRate(isCheckedRate1, 'second')"
                  id="flexCheckChecked"
                  style="margin: 7px -17px;">
            <star-rating [value]="val1"
                          [totalstars]="5"
                          checkedcolor="#ed6c37"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
            </star-rating>
          </div>
<!--
          <div class="form-check" style="margin-right: 15px;">
            <input class="form-check-input"
                    [(ngModel)]="isCheckedRate0"
                    (change)="handelChangeRate(isCheckedRate0, 'first')"
                    type="checkbox"
                    id="flexCheckChecked"
                    style="margin: 7px -17px;">
            <star-rating [value]="val"
                          [totalstars]="5"
                          checkedcolor="red"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
            </star-rating>
          </div> -->


        </div>

      </div>
    </div>
  </div>
</div>

