import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages.service';
import { SharedService } from 'src/app/shared/shared.service';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-search-side-menu',
  templateUrl: './search-side-menu.component.html',
  styleUrls: ['./search-side-menu.component.scss']
})
export class SearchSideMenuComponent implements OnInit {

  @Input() searchData: any;


  cities: City[];
  years: any[] = [];

  selectedCityCode: string;
  selectedYearsValue: string;

  length1:any ;
  length2:any ;
  price1:any;
  price2:any;
  rangeValues: number[];
  rangeValues2: number[];

  val:number = 0;
  val1:number = 1;
  val2:number = 2;
  val3:number = 3;
  val4:number = 4;
  val5: number = 5;

  isCheckedRate0: boolean = false;
  isCheckedRate1: boolean = false;
  isCheckedRate2: boolean = false;
  isCheckedRate3: boolean = false;
  isCheckedRate4: boolean = false;
  isCheckedRate5: boolean = false;


  //response data of features
  features:any;
  featuresItem: any = []; //features items array id

  //response data of rules
  rules:any;
  rulesItems: any = []; // rules item id

  //response data of cancel type
  cancel:any;

  //rules
  houseRoles:Array<any> = [];

  //cancelTypes
  cancelTypes:Array<any> = [];
  cancelsItemIds: any[] = [];

  //carvan type
  carvanTypes:Array<any> =[];

  //response caravan types
  carvanTypesResponse :any;

  //return data
  inf:any;



  @Output() onDatePicked = new EventEmitter<any>();


  constructor(private router: Router ,
    public translate: TranslationService,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    public sharedService: SharedService ) {

      this.cities = [
          {name: 'نيو ورك', code: 'NY'},
          {name: 'روما', code: 'RM'},
          {name: 'لندن', code: 'LDN'},
          {name: 'إسطنبول', code: 'IST'},
          {name: 'باريس', code: 'PRS'}
      ];

      this.length1 = 0;
      this.length2 =1000;
      this.rangeValues = [this.length1,this.length2];

      this.price1 = 0;
      this.price2 =1000;
      this.rangeValues2 = [this.price1, this.price2 ];
  }

  ngOnInit(): void {

    this.searchData;

    this.years = this.getYears();

    //get all features
    this.pagesService.getAllFeatures().subscribe(
      (response: any) => {
        this.features = response.data;
        console.log('featureesss===> ', this.features);
      },
      (error:any) => {
        console.log(error);
      }
    );

    //to get all rules
    this.pagesService.getRules().subscribe(
      (response: any) => {
        this.rules = response.data;
        console.log('ruleees ===> ', this.rules);
      },
      (error:any) => {
        console.log(error);
      }
    )

    //to get cancel reversations
    this.pagesService.getCancelType().subscribe(
      (response: any) => {
        this.cancel = response.data;
        console.log('canceeeel ===> ', this.cancel);
      },
      (error:any) => {
        console.log(error);
      }
    )

    this.pagesService.getCarvansType().subscribe(
      (response: any) => {
        this.carvanTypesResponse = response.data.map((item: any) => ({...item, active: false})); //to add active boolean
        console.log('getCarvansType =====> ', this.carvanTypesResponse);
      },
      (error:any) => {
        console.log(error);
      }
    )
  }

  //set years
  getYears() {
    var currentYear = new Date().getFullYear(), years = [];
    let startYear = 1980;
    while ( startYear <= currentYear ) {
      let x = startYear
        years.push({name:x++,value:startYear++ });
    }
    return years;
  }


//handel click on karavan type
handleType(caravanType: any) {
  caravanType.active = !caravanType.active;//[1,2,3]
  const index = this.carvanTypes.findIndex(ele => ele === caravanType.id);
  console.log('index', index);
  if(caravanType.active && index == -1) {
    this.carvanTypes.push(caravanType.id);
  } else {
    console.log('karavan id in in array already !');
    this.carvanTypes.splice(index, 1);
  }

  if(this.carvanTypes.length > 0) {
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      carvanTypes: this.carvanTypes
    };
  } else {
    delete this.pagesService.searchObject.carvanTypes;
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
    };
  };

  this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);

}


//handel all type of karavan
handleAllType() {
  console.log(this.carvanTypesResponse);
  this.carvanTypes = []; //empty karavan ever click
  this.carvanTypesResponse.forEach((karavan: any) => {
    karavan.active = !karavan.active;
    if(karavan.active) {
      this.carvanTypes.push(karavan.id);
    };
  });
  console.log(this.carvanTypes);

  if(this.carvanTypes.length > 0) {
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      carvanTypes: this.carvanTypes
    };
  } else {
    delete this.pagesService.searchObject.carvanTypes;
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
    };
  };
  this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
};


handelChangeYear(e: any) {
  console.log(e.target.value);
}

handleLength(){
  this.rangeValues = [this.length1,this.length2];
}

sliderlength() {
  this.length1 = this.rangeValues[0];
  this.length2 = this.rangeValues[1];
  this.pagesService.searchObject = {
    ...this.pagesService.searchObject,
    lengths: this.rangeValues
  };
  this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
};

handlePrice(){
  this.rangeValues2 = [this.price1,this.price2];
}

sliderPrice(){
  this.price1 = this.rangeValues2[0];
  this.price2 = this.rangeValues2[1];
  this.pagesService.searchObject = {
    ...this.pagesService.searchObject,
    prices: this.rangeValues2
  };
  this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  //this.search();
}

  //handel change feature
  onChangeFeature(item: any){
    const index = this.featuresItem.findIndex((e: any) => e.id === item.id);
    if(item.isChecked && index == -1) {
      this.featuresItem.push(item.id);
      console.log(this.featuresItem);
    } else {
      this.featuresItem.splice(index, 1);
    };
    console.log(this.featuresItem);
    if(this,this.featuresItem.length > 0) {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        features: this.featuresItem
      };
    } else {
      delete this.pagesService.searchObject.features;
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
      };
    }

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  //handel change rule
  handelChangeRule(item: any) {
    console.log(item);
    const index = this.rulesItems.findIndex((e: any) => e.id === item.id);
    if(item.isChecked && index == -1) {
      this.rulesItems.push(item.id);
    } else {
      this.rulesItems.splice(index, 1);
    };
    console.log(this.rulesItems);
    if(this,this.featuresItem.length > 0) {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        houseRoles: this.rulesItems
      };
    } else {
      delete this.pagesService.searchObject.houseRoles;
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
      };
    }
    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  //handel cancel change
  handelCancelChange(item: any) {
    console.log(item);
    const index = this.cancelsItemIds.findIndex((e: any) => e.id === item.id);
    if(item.isChecked && index == -1) {
      this.cancelsItemIds.push(item.id);
      console.log(this.cancelsItemIds);
    } else {
      this.cancelsItemIds.splice(index, 1);
    };
    console.log(this.cancelsItemIds);
    if(this,this.featuresItem.length > 0) {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        cancelTypes: this.cancelsItemIds
      };
    } else {
      delete this.pagesService.searchObject.cancelTypes;
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
      };
    }

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  handleCarvanTypes(f:any,value:any){
    if(f.target.checked){
      this.cancelTypes.push(value);
    }
    else{
      this.cancelTypes = this.cancelTypes.filter(e => e !== value);
    }
  }

  handelChangeRate(rateCheck: boolean, type: string) {

    console.log(rateCheck, type);
    if(!rateCheck) {
      delete this.pagesService.searchObject.rate;
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
      return;
    };

    //add rate of 0 to search object
    if(rateCheck && type == 'first') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }

    //add rate of 1 to search object
    if(rateCheck && type == 'second') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val1
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }

     //add rate of 2 to search object
    if(rateCheck && type == 'third') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val2
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }

    //add rate of 3 to search object
    if(rateCheck && type == 'fourth') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val3
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }
    //add rate of 4 to search object
    if(rateCheck && type == 'five') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val4
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }

    //add rate of 5 to search object
    if(rateCheck && type == 'six') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        rate: this.val5
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }

  }

}
