import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { SearchSideMenuComponent } from './search-side-menu/search-side-menu.component';
import { DataResultComponent } from './data-result/data-result.component';
import { CardComponent } from './card/card.component';
import {DropdownModule} from 'primeng/dropdown';
import {SliderModule} from 'primeng/slider';
import { SharedModule } from 'src/app/shared/shared.module';
import { PagesSharedModule } from '../pagesShared.module';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [SearchComponent,
    SearchSideMenuComponent,
      DataResultComponent,
      CardComponent],
  imports: [
    CommonModule,
    SearchRoutingModule,
    DropdownModule,
    SliderModule,
    FormsModule,
    SharedModule,
    PagesSharedModule
  ],
  providers: [
    DatePipe
  ]
})
export class SearchModule { }
