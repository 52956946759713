
<div [ngClass]="{'search-result': translate.is_arabic, 'search-resultEn': !translate.is_arabic}">
    <div class="header-result sticky-md-top">
        <div class="row">
            <div class="col-12 col-md-4">
                <p>{{translate.data.result.title}} <span> {{translate.data.result.totalNumber}} : {{karavanLen}}</span></p>
            </div>
            <div class="col-12 col-md-6" style="direction: ltr;">
                <div class="row">

                   <div class="col-12 col-md-4">
                        <p-dropdown [options]="rangesData"
                            (onChange)="changePrice()"
                            [(ngModel)]="selectedOptionSalary"
                            [optionLabel]="translate.data.requestdData.genName"
                            optionValue="value"
                            styleClass="customClass"></p-dropdown>
                            <!-- <img class="arrow-icon" src="../../../../assets/Path 6.png" style="position: absolute;top: 8px;right: 125px;" /> -->
                   </div>

                </div>
            </div>
            <div class="col-12 col-md-2">
                <p class="showMap">{{translate.data.result.showMap}}</p>
            </div>
            <div class="col-12">
                <div class="fillter2">
                    <div class="row">
                        <div class="col-12 col-md-2 reactiveC">
                            <img class="img" src="../../../../assets/Content/location-pin/location-pin.png" />
                            <select (change)="handelChangeCountry($event)" [(ngModel)]="selectedCountry">
                                <option [value]="'country'">{{translate.is_arabic ? 'الدوله' : 'Country'}}</option>
                                <option *ngFor="let country of countries" [value]="country.id">
                                    {{ country[translate.data.requestdData.genName] }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-2 reactiveC">
                            <img class="img" src="../../../../assets/Content/location-pin/location-pin.png" />
                            <select (change)="handelChangeCity($event)" [(ngModel)]="selectedCity">
                                <option [value]="'city'">{{translate.is_arabic ? 'المدينه' : 'City'}}</option>
                                <option *ngFor="let city of cities" [value]="city.id">
                                    {{city[translate.data.requestdData.genName]}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-2 reactiveC">
                            <img src="../../../../assets/Content/calendar/calendar.png"  class="img"/>
                            <p-calendar [(ngModel)]="from"
                            [placeholder]="translate.is_arabic ? ' المغادره' : 'leave Date'"
                                (onSelect)="handelDateLeave()"
                                dateFormat="dd.mm.yy"
                                styleClass="calenderStyle"
                                >
                            </p-calendar>
                        </div>
                        <div class="col-12 col-md-2 reactiveC">
                            <img src="../../../../assets/Content/calendar/calendar.png"  class="img"/>
                            <p-calendar [(ngModel)]="to"
                                dateFormat="dd.mm.yy"
                                [placeholder]="translate.is_arabic ? ' العوده' : 'Back Date'"
                                styleClass="calenderStyle">
                            </p-calendar>
                        </div>
                        <div class="col-12 col-md-2 reactiveC">
                            <img src="../../../../assets/Content/bed/bed.png" style="height: 13px;;" class="img" />
                            <select (change)="handelChangeBed($event)">
                                <option>{{translate.is_arabic ? 'سرير' : "Beds"}}</option>
                                <option *ngFor="let bed of beds" [value]="bed.id">{{bed.name}}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-2 reactiveC">
                            <img src="../../../../assets/Content/office-chair/office-chair.png" class="img"/>
                            <select (change)="handelChangeSite($event)">
                                <option>{{translate.is_arabic ? 'مقعد' : "Chairs"}}</option>
                                <option *ngFor="let site of sites" [value]="site.id">{{site.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="karavan-result">
        <app-karavan></app-karavan>
    </div>


</div>
