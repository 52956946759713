import { Component, OnInit ,Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService } from '../../pages.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../../authentication/Services/authentication.service';
import { User } from 'src/app/authentication/user.model';


@Component({
  selector: 'app-data-result',
  templateUrl: './data-result.component.html',
  styleUrls: ['./data-result.component.scss']
})
export class DataResultComponent implements OnInit {

  @Input('karavanLen') karavanLen: number;

  rangesData: any[] = [
    {value: '0', nameAr: 'ترتيب السعر', nameEn: 'Hair arrangement'},
    {value: '2', nameAr: 'أعلي سعر', nameEn: 'Higher'},
    {value: '1', nameAr: 'أقل سعر', nameEn: 'Lower'}
  ];

  optionsNearData: any[] =[
    {value: '0', name_ar: 'الأقرب'},
  ];

  //countries data
  countries: any[];
  selectedCountry: number;
  //citieis data
  cities: any[];
  selectedCity: number;

  //beds data
  beds: any[];
  sites: any[];

  selectedOptionSalary: any;
  nearOption: any;

  dataParams: any; //data receive from routing

  //data Picker data
  from: any;
  to: any;

  userData: User;

  pageNumber:any;

  constructor(public translate: TranslationService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private authSer: AuthenticationService,
    private pagesService: PagesService) { }

  ngOnInit(): void {
    this.userData = this.authSer.getUser();

    this.spinner.show();
    //get countries data
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        this.countries = response.data;
      },
      (error) => {
        console.log(error);
      }
    );

    //beds data
    this.beds = [
      {id: '1', name: 1},
      {id: '2', name: 2},
      {id: '3', name: 3},
      {id: '4', name: 4},
      {id: '5', name: 5}
    ];

    //sites data
    this.sites = [
      {id: '1', name: 1},
      {id: '2', name: 2},
      {id: '3', name: 3},
      {id: '4', name: 4},
      {id: '5', name: 5}
    ];



    this.route.queryParams.subscribe(
      (params: Params) => {
        this.dataParams = JSON.parse(params['data']);
        this.pageNumber = JSON.parse(params['pageNo']);
        console.log('query params  => ', this.dataParams);
        this.selectedCountry = this.dataParams.CountryId ? this.dataParams.CountryId : 'country';
        this.getCities(this.selectedCountry);
        this.selectedCity = this.dataParams.cityId ? this.dataParams.cityId : 'city';

        this.pagesService.searchObject = {
          CountryId: this.selectedCountry,
          cityId: this.selectedCity
        };

        //to get karavans by search object
        if(Object.keys(this.dataParams).length === 0){
          console.log("enter");

          this.pagesService.getAllCarvan(this.pageNumber).subscribe(
            (response:any) => {
              this.karavanLen = response.data?.resCarvans.length;
              this.pagesService.karavans.next(response.data);
              this.spinner.hide();
            },
            (error) => {
              console.log(error);
              this.spinner.hide();
            }
          );

        }else{
          console.log("enter else");

          this.pagesService.getKaravansBySearch(this.dataParams , this.pageNumber).subscribe(
            (response:any) => {
              console.log("data params",this.dataParams);

              this.karavanLen = response.data?.resCarvans.length;
              this.pagesService.karavans.next(response.data);
              this.spinner.hide();
            },
            (error) => {
              console.log(error);
              this.spinner.hide();
            }
          );
        }

      }
    );

  }


  //handelChangeCountry
  handelChangeCountry(e: any) {
    console.log('===>', e.target.value);

    if(e.target.value != 'country') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        CountryId: +e.target.value
      };
    } else {
      delete this.pagesService.searchObject.CountryId;
    }


    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    this.getCities(e.target.value);
  }

  //handelChangeCity
  handelChangeCity(e: any) {
    console.log(e.target.value);
    if(e.target.value != 'city') {
      this.pagesService.searchObject = {...this.pagesService.searchObject,
        cityId: +e.target.value
      };
    } else {
      delete this.pagesService.searchObject.cityId;
    }

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  //handelChangeBed
  handelChangeBed(e:any) {
    console.log(e.target.value);
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      bedsNo: +e.target.value
    };

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  //handelChangeSite
  handelChangeSite(e: any) {
    console.log(e.target.value);
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      seatNo: +e.target.value
    };

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  };

  //getCities
  getCities(countryId: number) {
    this.pagesService.getCity(countryId).subscribe(
      (response: any) => {
        console.log(response);
        this.cities = response.data;
      },
      (error) => {
        console.log(error);
      }
    )
  };

  //hande leave date
  handelDateLeave() {
    console.log(this.from);
    const fromDate = this.datepipe.transform(this.from, 'yyyy-MM-dd')
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      from: fromDate
    };

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }

  //handel bacj date
  handelBackDate() {
    console.log(this.to);
    const toDate = this.datepipe.transform(this.to, 'yyyy-MM-dd')
    this.pagesService.searchObject = {
      ...this.pagesService.searchObject,
      to: toDate
    };

    this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
  }


  changePrice() {
    console.log(this.selectedOptionSalary);
    
    if(this.selectedOptionSalary != '0') {
      this.pagesService.searchObject = {
        ...this.pagesService.searchObject,
        priceOrder: +this.selectedOptionSalary
      };
      this.pagesService.filtterSearchKaravanObject.next(this.pagesService.searchObject);
    }
  }

}
